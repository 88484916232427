#root {
    background-color: #282c34;
    height: 100vh;
}

.App {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    font-size: calc(10px + 1vmin);
    color: white;
}

.sidebar {
    height: 100%;
    width: 150px;
    border-right: 1px solid #ccc;

    &.right {
        border-left: 1px solid #ccc;
        border-right: none;
    }
}

.sidebar .chatLine {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#sidebar {
    width: 200px;
    padding-left: 30px;

}

.chat.active {
    background-color: #2469a6;

}

#main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: flex-start;
    flex-grow: 1;



}

#mainChat {
    text-align: center;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    height: 100%;
    width: 100%;

}


.header {
    background-color: whitesmoke;
    flex-direction: row;
    display: flex;
    align-items: flex-start;
    width: 100%;
    color: black;

    >* {
        flex: 1;
        align-self: center;
    }
}

#messages {
    color: white;
    text-align: justify;
    padding: 15px;
    line-height: 2;
    overflow-y: scroll;
    display: flex;
    flex-grow: 1;
    flex-direction: column;

    .message {
        margin-top: 30px;

        &.assistant {
            p {
                color: white;
            }
        }

        &.user {
            background-color: #2469a6;
            padding: 1px 40px;

            p {
                color: white;
                font-size: 120%;

            }
        }

        &.system {
            p {
                color: #ccc;
                font-size: 80%;
                font-style: italic;
            }
        }
    }
}

#input {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    text-align: center;
    justify-content: center;


    form,
    textarea {
        min-width: 80%;
        min-height: 100px;
    }
}